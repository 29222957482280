export function openFullscreen(el) {
    if (el.requestFullscreen) {
        el.requestFullscreen();
    }
    else if (el.webkitRequestFullscreen) { /* Safari */
        el.webkitRequestFullscreen();
    }
    else if (el.msRequestFullscreen) { /* IE11 */
        el.msRequestFullscreen();
    }
}
export function closeFullscreen() {
    let doc = document;
    if (doc.exitFullscreen) {
        doc.exitFullscreen();
    }
    else if (doc.webkitExitFullscreen) { /* Safari */
        doc.webkitExitFullscreen();
    }
    else if (doc.msExitFullscreen) { /* IE11 */
        doc.msExitFullscreen();
    }
}
export function isFunction(functionToCheck) {
    const toStr = {}.toString.call(functionToCheck).toLowerCase();
    return functionToCheck && toStr === '[object function]';
}
export function isAsyncFunction(functionToCheck) {
    const toStr = {}.toString.call(functionToCheck).toLowerCase();
    return functionToCheck && toStr === '[object asyncfunction]';
}
export function isPromise(value) {
    return value && typeof value.then === 'function';
}
