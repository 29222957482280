export const WebcamContainerClassName = 'webcam-container';
export const WebcamClassName = 'webcam';
export const WebcamControlClassName = 'webcam-control';
export const WebcamControlLeftClassName = 'webcam-control-left';
export const WebcamControlCenterClassName = 'webcam-control-center';
export const WebcamControlRightClassName = 'webcam-control-right';
export const WebcamVideoClassName = 'webcam-video';
export const WebcamVideoContainerClassName = 'webcam-video-container';
export const WebcamSettingDialogClassName = 'webcam-setting-dialog';
export const WebcamControlItemClassName = 'webcam-control-item';
export const WebcamIconClassName = 'webcam-icon';
export const WebcamSettingHeaderClassName = 'webcam-setting-header';
export const WebcamSettingGroupClassName = 'webcam-setting-group';
export const WebcamSettingListClassName = 'webcam-setting-list';
export const WebcamSettingWrapperClassName = 'webcam-setting-wrapper';
export const WebcamSettingCheckClassName = 'webcam-setting-check';
export const WebcamSettingCheckIconClassName = 'webcam-setting-check-icon';
export const ActiveClassName = 'active';
