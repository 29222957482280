var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FullScreenIcon, FullscreenExitIcon, SettingIcon } from "./assets";
import { WebcamClassName, WebcamContainerClassName, WebcamControlCenterClassName, WebcamControlClassName, WebcamControlItemClassName, WebcamControlLeftClassName, WebcamControlRightClassName, WebcamIconClassName, WebcamSettingCheckClassName, WebcamSettingCheckIconClassName, WebcamSettingDialogClassName, WebcamSettingGroupClassName, WebcamSettingHeaderClassName, WebcamSettingListClassName, WebcamSettingWrapperClassName, WebcamVideoClassName, WebcamVideoContainerClassName } from "./constants";
import { closeFullscreen, openFullscreen, isFunction, isAsyncFunction, isPromise } from "./utils";
export function createWebcamLayout() {
    const containerEl = document.createElement('div');
    containerEl.className = WebcamContainerClassName;
    const webcamEl = document.createElement('div');
    webcamEl.className = WebcamClassName;
    const webcamVideoEl = document.createElement('div');
    webcamVideoEl.className = WebcamVideoClassName;
    const webcamVideoContainerEl = document.createElement('div');
    webcamVideoContainerEl.className = WebcamVideoContainerClassName;
    const videoEl = document.createElement('video');
    videoEl.setAttribute('autoplay', 'true');
    videoEl.setAttribute('playsinline', 'true');
    videoEl.setAttribute('muted', 'true');
    containerEl.appendChild(webcamEl);
    webcamVideoEl.appendChild(webcamVideoContainerEl);
    webcamVideoContainerEl.appendChild(videoEl);
    const webcamControlEl = document.createElement('div');
    webcamEl.append(webcamVideoEl, webcamControlEl);
    webcamControlEl.className = WebcamControlClassName;
    const webcamControlLeftEl = document.createElement('div');
    webcamControlLeftEl.className = WebcamControlLeftClassName;
    const webcamControlCenterEl = document.createElement('div');
    webcamControlCenterEl.className = WebcamControlCenterClassName;
    const webcamControlRightEl = document.createElement('div');
    webcamControlRightEl.className = WebcamControlRightClassName;
    webcamControlEl.append(webcamControlLeftEl, webcamControlCenterEl, webcamControlRightEl);
    const webcamSettingControlEl = document.createElement('div');
    webcamSettingControlEl.className = WebcamControlItemClassName;
    const webcamSettingDialogEl = createSettingDialog();
    const settingIconEl = createIcon(SettingIcon);
    settingIconEl.addEventListener('click', evt => handleOpenSettingDialog(evt, webcamSettingDialogEl));
    document.addEventListener('click', evt => handleCloseSettingDialog(evt, settingIconEl, webcamSettingDialogEl));
    webcamSettingControlEl.append(settingIconEl, webcamSettingDialogEl);
    const webcamFullScreenControl = document.createElement('div');
    webcamFullScreenControl.className = WebcamControlItemClassName;
    const fullscreenIconEl = createIcon(FullScreenIcon);
    document.addEventListener('fullscreenchange', _ => {
        if (document.fullscreenElement)
            fullscreenIconEl.innerHTML = FullscreenExitIcon;
        else
            fullscreenIconEl.innerHTML = FullScreenIcon;
    });
    fullscreenIconEl.addEventListener('click', _ => {
        if (!document.fullscreenElement)
            openFullscreen(containerEl);
        else
            closeFullscreen();
    });
    webcamFullScreenControl.appendChild(fullscreenIconEl);
    webcamControlRightEl.append(webcamSettingControlEl, webcamFullScreenControl);
    return containerEl;
}
function handleOpenSettingDialog(evt, diaglogEl) {
    evt.preventDefault();
    diaglogEl.classList.toggle('active');
}
function handleCloseSettingDialog(evt, iconEl, diaglogEl) {
    evt.preventDefault();
    const target = evt.target;
    if (!iconEl.contains(target) && !diaglogEl.contains(target)) {
        diaglogEl.classList.remove('active');
    }
}
function createSettingDialog() {
    const dialogEl = document.createElement('div');
    dialogEl.className = WebcamSettingDialogClassName;
    dialogEl.append(createSettingGroup({
        title: 'Camera',
        classList: ['webcam-setting-camera-group']
    }));
    return dialogEl;
}
function createSettingGroup(config) {
    const groupEl = document.createElement('div');
    groupEl.className = WebcamSettingGroupClassName;
    if (Array.isArray(config.classList)) {
        config.classList.forEach(className => groupEl.classList.add(className));
    }
    const groupHeaderEl = document.createElement('div');
    groupHeaderEl.className = WebcamSettingHeaderClassName;
    if (!config.collapsable) {
        groupEl.classList.add('active');
        groupHeaderEl.innerHTML = config.title;
    }
    else {
        groupHeaderEl.classList.add(WebcamSettingWrapperClassName);
        const titleSpanEl = document.createElement('span');
        titleSpanEl.innerHTML = config.title;
        const webcamCheckEl = document.createElement('div');
        webcamCheckEl.className = WebcamSettingCheckClassName;
        const webcamCheckIconEl = document.createElement('i');
        webcamCheckIconEl.className = WebcamSettingCheckIconClassName;
        webcamCheckEl.appendChild(webcamCheckIconEl);
        if (!config.collapse) {
            groupEl.classList.add('active');
            webcamCheckEl.classList.add('active');
        }
        groupHeaderEl.append(titleSpanEl, webcamCheckEl);
    }
    const groupBodyEl = document.createElement('div');
    groupBodyEl.className = WebcamSettingListClassName;
    const ulEl = document.createElement('ul');
    groupBodyEl.appendChild(ulEl);
    groupEl.append(groupHeaderEl, groupBodyEl);
    return groupEl;
}
function createIcon(icon) {
    const iconEl = document.createElement('button');
    iconEl.innerHTML = icon;
    iconEl.className = WebcamIconClassName;
    return iconEl;
}
export function renderCameraList(cameras, layout, onChange) {
    let currentValue;
    if (cameras && cameras.length > 0 && layout) {
        const cameraUlEl = layout.querySelector('.webcam-setting-camera-group ul');
        if (cameraUlEl) {
            let currentLiEl;
            let switching = false;
            cameras.forEach((camera, ix) => {
                const value = camera.id;
                const label = camera.label;
                const liEl = document.createElement('li');
                liEl.innerHTML = `<i></i><span>${label}</span>`;
                if (ix == 0) {
                    liEl.classList.add('active');
                    currentValue = camera;
                    currentLiEl = liEl;
                }
                cameraUlEl.appendChild(liEl);
                if (isFunction(onChange) || isAsyncFunction(onChange)) {
                    liEl.addEventListener('click', (evt) => __awaiter(this, void 0, void 0, function* () {
                        evt.preventDefault();
                        if ((currentValue === null || currentValue === void 0 ? void 0 : currentValue.id) != value && !switching) {
                            switching = true;
                            currentValue = camera;
                            currentLiEl === null || currentLiEl === void 0 ? void 0 : currentLiEl.classList.remove('active');
                            liEl.classList.add('active');
                            currentLiEl = liEl;
                            const rs = onChange(evt, camera);
                            if (isPromise(rs))
                                yield rs;
                            switching = false;
                        }
                    }));
                }
            });
        }
    }
    return currentValue;
}
