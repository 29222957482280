var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Webcam } from "./webcam";
const StopBtn = document.getElementById('stop-btn');
const StartBtn = document.getElementById('start-btn');
const PauseBtn = document.getElementById('pause-btn');
const ResumeBtn = document.getElementById('resume-btn');
const webcam = new Webcam({
    video: {
        aspectRatio: 1
    }
});
webcam.render('test');
webcam.play().then(videoSize => {
    alert(`Video width: ${videoSize.width}\nVideo heigth: ${videoSize.height}`);
    StopBtn.addEventListener('click', (evt) => __awaiter(void 0, void 0, void 0, function* () {
        evt.preventDefault();
        try {
            yield webcam.stop();
        }
        catch (err) {
            alert(`Error: ${err}`);
        }
    }));
    StartBtn.addEventListener('click', (evt) => __awaiter(void 0, void 0, void 0, function* () {
        evt.preventDefault();
        try {
            yield webcam.play();
        }
        catch (err) {
            alert(`Error: ${err}`);
        }
    }));
    PauseBtn.addEventListener('click', evt => {
        evt.preventDefault();
        try {
            webcam.pause();
        }
        catch (err) {
            alert(`Error: ${err}`);
        }
    });
    ResumeBtn.addEventListener('click', evt => {
        evt.preventDefault();
        try {
            webcam.resume();
        }
        catch (err) {
            alert(`Error: ${err}`);
        }
    });
});
