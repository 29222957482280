export function throwIfNullOrUndefined(value) {
    if (value === undefined || value === null) {
        throw new Error('Value is null or undefined');
    }
}
export function throwIfNullOrEmpty(value) {
    if (value === null) {
        throw new Error('Value is null');
    }
    if (typeof (value) !== 'string') {
        throw new Error('Value is not a string');
    }
    value = value.trim();
    if (value === '') {
        throw new Error('Value is empty');
    }
}
