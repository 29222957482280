var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CameraRetriever } from "./retriever";
import { throwIfNullOrEmpty, throwIfNullOrUndefined } from "./throw-helper";
import { createWebcamLayout, renderCameraList } from "./ui-helper";
export class Webcam {
    constructor(mediaStreamConstraints) {
        this.mediaStream = null;
        this.isStopped = true;
        this.switching = false;
        this.isPaused = true;
        this.mediaStreamConstraints = this.createConstraints(mediaStreamConstraints);
        this.layoutElement = createWebcamLayout();
        this.videoElement = this.layoutElement.querySelector('video');
    }
    getLayoutElement() {
        return this.layoutElement;
    }
    getVideoElement() {
        return this.videoElement;
    }
    getMediaStream() {
        return this.mediaStream;
    }
    getCurrentCamera() {
        return this.currentCamera;
    }
    render(containerSelector) {
        throwIfNullOrEmpty(containerSelector);
        containerSelector = containerSelector.trim();
        const containerEl = document.querySelector(containerSelector) || document.getElementById(containerSelector);
        throwIfNullOrUndefined(containerEl);
        containerEl.appendChild(this.layoutElement);
    }
    play() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isStopped)
                return Promise.reject("The camera is playing.");
            if (this.switching)
                return Promise.reject("The camera is switching.");
            return this.playCore();
        });
    }
    pause() {
        this.failIfPaused();
        this.failIfStopped();
        this.failIfSwitching();
        this.videoElement.pause();
        this.isPaused = true;
    }
    resume(onResumed) {
        this.failIfStopped();
        this.failIfSwitching();
        if (!this.isPaused)
            throw 'Video is not paused';
        this.isPaused = false;
        let $this = this;
        const onVideoResume = () => {
            if (onResumed) {
                // Transition after 200ms to avoid the previous canvas frame being
                // re-scanned.
                setTimeout(onResumed, 200);
            }
            $this.videoElement.removeEventListener("playing", onVideoResume);
        };
        this.videoElement.addEventListener("playing", onVideoResume);
        this.videoElement.play();
    }
    stop() {
        if (this.switching)
            return Promise.reject("The camera is switching.");
        if (this.isStopped)
            return Promise.reject("The camera is stopped.");
        if (!this.mediaStream) {
            this.isStopped = true;
            return Promise.reject("The camera is not played.");
        }
        return this.stopCore();
    }
    changeConstraints(cameraId) {
        if (typeof (this.mediaStreamConstraints.video) == 'boolean') {
            this.mediaStreamConstraints.video = {
                deviceId: cameraId
            };
        }
        else if (typeof (this.mediaStreamConstraints.video) == 'object') {
            this.mediaStreamConstraints.video.deviceId = cameraId;
        }
    }
    createConstraints(init) {
        const defaultConstraint = {
            audio: false,
            video: true
        };
        if (!init || typeof (init) != 'object')
            return defaultConstraint;
        if (typeof (init.audio) == 'boolean' || typeof (init.audio) == 'object')
            defaultConstraint.audio = init.audio;
        if (typeof (init.video) == 'boolean' || typeof (init.video) == 'object')
            defaultConstraint.video = init.video;
        return defaultConstraint;
    }
    playCore() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const cameras = yield CameraRetriever.retrieve();
            if (!this.currentCamera) {
                this.currentCamera = renderCameraList(cameras, this.layoutElement, this.onCameraChange.bind(this));
            }
            this.changeConstraints((_a = this.currentCamera) === null || _a === void 0 ? void 0 : _a.id);
            this.mediaStream = yield navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints);
            return yield this.setupVideoElement(this.mediaStream);
        });
    }
    stopCore() {
        let $this = this;
        return new Promise((resolve, _) => {
            let tracks = $this.mediaStream.getVideoTracks();
            const tracksToClose = tracks.length;
            var tracksClosed = 0;
            $this.mediaStream.getVideoTracks().forEach((videoTrack) => {
                $this.mediaStream.removeTrack(videoTrack);
                videoTrack.stop();
                ++tracksClosed;
                if (tracksClosed >= tracksToClose) {
                    $this.isStopped = true;
                    $this.isPaused = true;
                    $this.mediaStream = null;
                    $this.videoElement.srcObject = null;
                    resolve();
                }
            });
        });
    }
    onCameraChange(evt, cameraDevice) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.mediaStream) {
                this.switching = true;
                yield this.stopCore();
                this.currentCamera = cameraDevice;
                yield this.playCore();
                this.switching = false;
            }
            else {
                this.currentCamera = cameraDevice;
            }
        });
    }
    setupVideoElement(mediaStream) {
        this.isStopped = false;
        this.isPaused = false;
        return new Promise((resolve, reject) => {
            let onabort = () => {
                this.isStopped = true;
                this.isPaused = true;
                reject("Video element onabort() called");
            };
            let onerror = () => {
                this.isStopped = true;
                this.isPaused = true;
                reject("Video element onerror() called");
            };
            let onVideoStart = () => {
                const videoWidth = this.videoElement.clientWidth;
                const videoHeight = this.videoElement.clientHeight;
                this.videoElement.removeEventListener("abort", onabort);
                this.videoElement.removeEventListener("error", onerror);
                this.videoElement.removeEventListener("playing", onVideoStart);
                resolve({ width: videoWidth, height: videoHeight });
            };
            this.videoElement.addEventListener("abort", onabort);
            this.videoElement.addEventListener("error", onerror);
            this.videoElement.addEventListener("playing", onVideoStart);
            this.videoElement.srcObject = mediaStream;
            this.videoElement.play();
        });
    }
    failIfPaused() {
        if (this.isPaused) {
            throw "The camera has already been paused.";
        }
    }
    failIfStopped() {
        if (this.isStopped) {
            throw "The camera has already been stopped.";
        }
    }
    failIfSwitching() {
        if (this.switching) {
            throw "The camera is switching.";
        }
    }
}
